import React, {
  ReactElement,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

import { theme } from 'theme';

import { dimensionsRegExp, temperatureRegExp } from 'shared/constants/regExp';
import { useFormikError } from 'shared/hooks/useFormikError';

import {
  defaultLengths,
  defaultWidths,
  defaultHeights,
  ShipmentItemType,
  volumeIsEditable,
  ShipmentItemSubType,
} from 'shared/types/shipments/shipmentItem';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import { usePrevious } from 'shared/hooks/usePrevious';
import Typography from '@material-ui/core/Typography';
import { Labeled } from '../CustomMaterial/Labeled/Labeled';
import {
  shipmentItemSubTypesToTypes,
  shipmentItemTypesToSubTypes,
} from '../../shared/functions/shipments/shipmentItemTypesMapping';
import {
  shipmentItemSubTypeLabels,
  shipmentItemTypeLabels,
} from '../../shared/constants/shipment/shipmentItemTypeLabels';
import { ldmIsRequired, sizeIsRequired } from '../../shared/hooks/useNewShipmentValidation';
import { DropdownIcon } from '../Icons/DropdownIcon/DropdownIcon';
import { Switch } from 'react-router-dom';
import { TextFieldFormik } from '../CustomMaterial/TextField/TextFieldFormik';
import { Accordion } from '../CustomMaterial/Accordion/Accordion';
import { AccordionSummary } from '../CustomMaterial/Accordion/AccordionSummary';
import { ShipmentItemIcon } from './ShipmentItemIcon';
import { QuantityEditor } from './ItemsStep/QuantityEditor';
import { NewShipmentContext } from '../../scenes/Order/Order';
import { SelectFormik } from '../CustomMaterial/Select/SelectFormik';

const useStyles = makeStyles({
  itemIcon: {
    marginRight: theme.spacing(3),
  },
  smallText: {
    fontSize: '0.75rem',
  },
  fixedWidth: {
    width: theme.spacing(20),
  },
  cellLabel: {
    marginBottom: theme.spacing(1),
    color: theme.palette.common.veryDarkGrayAlt2,
    display: 'flex',
    alignItems: 'center',
  },
  cellText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cellWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  tooltipIcon: {
    marginLeft: theme.spacing(1),
  },
  coldFrozenHyphen: {
    color: theme.palette.common.gray,
    margin: theme.spacing(0, 2),
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  dangerousGoods: {
    marginRight: theme.spacing(36.25),
  },
  coldFrozen: {
    marginRight: theme.spacing(14),
  },
  stackable: {
    marginRight: theme.spacing(10),
  },
  quantityEditor: {
    [theme.breakpoints.down(1440)]: {
      marginRight: theme.spacing(3),
    },
  },
  shipmentType: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down(1440)]: {
      marginRight: theme.spacing(1),
    },
  },
  detailsRow: {
    paddingLeft: theme.spacing(62.5),
    [theme.breakpoints.down(1440)]: {
      paddingLeft: theme.spacing(53),
    },
  },
  volume: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down(1440)]: {
      marginRight: theme.spacing(1),
    },
  },
  totalWeight: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down(1440)]: {
      marginRight: theme.spacing(1),
    },
  },
  productDescription: {
    width: 638,
    [theme.breakpoints.down(1440)]: {
      width: 640,
    },
  },
});

export const ItemEditable = ({ name, index, onDelete, isTemplateView }): ReactElement => {
  const formik = useFormikContext();
  const classes = useStyles();
  const { t } = useTranslation();
  const { setAutomaticPricesState } = useContext(NewShipmentContext);

  const [expanded, setExpanded] = useState(false);

  const {
    typeId,
    quantityId,
    stackable,
    delicateGoods,
    dangerousGoods,
    coldFrozen,
    length,
    width,
    height,
    weight,
    volume,
    totalWeight,
    ldm,
  } = get(formik.values, name) ?? {};

  const previousLdm = usePrevious(ldm);
  const previousVolume = usePrevious(volume);

  const toggleExpanded = () => setExpanded(!expanded);

  const transitionProps = { timeout: 200 };
  const iconButtonProps = { onClick: toggleExpanded };

  const itemType = !isNil(typeId) ? shipmentItemSubTypesToTypes[typeId] : ShipmentItemType.Other;
  const itemTypeLabel = !isNil(itemType) ? t(shipmentItemTypeLabels[itemType]) : '';

  const extraPanelVisible = dangerousGoods || coldFrozen;

  const verticalLine = (transparent = false) => (
    <Box
      width="1px"
      height={51}
      bgcolor={transparent ? 'transparent' : theme.palette.common.lightGray}
    />
  );

  const setDefaultMeasures = () => {
    if (typeId) {
      if (
        !defaultLengths[typeId] ||
        !length ||
        (defaultLengths[typeId] && defaultLengths[typeId] !== length)
      ) {
        formik.setFieldValue(`${name}.length`, (defaultLengths[typeId] || length) ?? null, false);
      }
      if (
        !defaultLengths[typeId] ||
        !width ||
        (defaultWidths[typeId] && defaultWidths[typeId] !== width)
      ) {
        formik.setFieldValue(`${name}.width`, (defaultWidths[typeId] || width) ?? null, false);
      }
      if (
        !defaultHeights[typeId] ||
        !height ||
        (defaultHeights[typeId] && defaultHeights[typeId] !== height)
      ) {
        formik.setFieldValue(`${name}.height`, (defaultHeights[typeId] || height) ?? null, false);
      }
    }
  };

  useEffect(() => {
    setAutomaticPricesState('InProgress');
  }, [setAutomaticPricesState, length, width, height, quantityId, weight, ldm, typeId]);

  const calculateVolume = () => {
    let defaultVolume = null;
    if (length && width && height) {
      defaultVolume = (
        (parseFloat(length.toString()) / 100) *
        (parseFloat(width.toString()) / 100) *
        (parseFloat(height.toString()) / 100) *
        quantityId
      ).toFixed(3);
    }
    if (volume !== defaultVolume) {
      formik.setFieldValue(`${name}.volume`, defaultVolume, false);
    }
  };

  const calculateWeight = () => {
    let defaultWeight = '0';
    if (weight) {
      defaultWeight = (parseFloat(weight.toString()) * quantityId).toFixed(1);
    }
    formik.setFieldValue(`${name}.totalWeight`, defaultWeight, false);
  };

  const calculateLdm = () => {
    let defaultLdm = null;
    if (length && length > 0 && width && width > 0) {
      defaultLdm = ((((length / 100) * (width / 100)) / 2.4) * quantityId).toFixed(2);
    }
    if (ldm !== defaultLdm && defaultLdm) {
      formik.setFieldValue(`${name}.ldm`, defaultLdm, false);
    }
  };

  const onDangerousGoodsFlipped = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target?.checked;

    formik.setFieldValue(`${name}.dangerousGoods`, isChecked, false);
    formik.setFieldValue(`${name}.dangerousGoodsInfo`, isChecked ? [{}] : undefined, false);
  };

  useEffect(() => {
    if ((volume ?? 0) === (previousVolume ?? 0)) {
      calculateVolume();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length, width, height, quantityId]);

  useEffect(() => {
    calculateWeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weight, quantityId]);

  useEffect(() => {
    if ((ldm ?? 0) === (previousLdm ?? 0)) {
      calculateLdm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length, width, quantityId, ldm]);

  useEffect(() => {
    setDefaultMeasures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeId]);

  const onItemDelete = () => {
    if (onDelete) {
      onDelete(index);
    }
  };

  const isFormikError = useFormikError();

  const isError = (fieldName: string) =>
    Array.isArray(formik.errors.shipmentDetailsRows) ? isFormikError(fieldName) : false;

  const fieldsNotFilled =
    isError(`${name}.ldm`) ||
    isError(`${name}.typeId`) ||
    isError(`${name}.length`) ||
    isError(`${name}.width`) ||
    isError(`${name}.height`) ||
    isError(`${name}.weight`) ||
    isError(`${name}.dangerousGoodsInfo[0].un`) ||
    isError(`${name}.dangerousGoodsInfo[0].classVal`) ||
    isError(`${name}.temperature`) ||
    isError(`${name}.temperatureMax`);

  const quantityChanged = useCallback(
    (val: number) => formik.setFieldValue(`${name}.quantityId`, val),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name],
  );

  const renderMeasurementField = (
    type: 'width' | 'length' | 'height',
    value: number | undefined,
  ) => (
    <Labeled
      text={t(`SHIPMENT.ITEM.DETAILS.${type.toUpperCase()}`)}
      error={isError(`${name}.${type}`)}
      variant="variant2"
      marginLeft={type === 'length' ? 3 : 0}
      marginRight={2}
      tooltip={value ? t(`SHIPMENT.ITEM.DETAILS.${type.toUpperCase()}_TOOLTIP`) : ''}
      required={
        value
          ? undefined
          : !isTemplateView && (sizeIsRequired(typeId?.toString() ?? '') || type === 'height')
      }
    >
      {value ? (
        <Typography
          className={clsx(classes.smallText, classes.fixedWidth)}
          variant="body2"
          marginTop={3}
        >
          {value} cm
        </Typography>
      ) : (
        <TextFieldFormik
          name={`${name}.${type}`}
          error={isError(`${name}.${type}`)}
          variant="variant6"
          type="text"
          size="small"
          width={80}
          unit="cm"
          numeric
          debounced
          isReadonly={
            !isTemplateView && !sizeIsRequired(typeId?.toString() ?? '') && type !== 'height'
          }
          allowedChars={dimensionsRegExp}
        />
      )}
    </Labeled>
  );

  const renderVolumeField = (editable: boolean | undefined) => (
    <Labeled
      text={t('SHIPMENT.ITEM.DETAILS.VOLUME')}
      error={isError(`${name}.volume`)}
      variant="variant2"
      className={classes.volume}
      required={!editable || isTemplateView ? undefined : sizeIsRequired(typeId?.toString() ?? '')}
    >
      {!editable ? (
        <Typography className={classes.smallText} variant="body2" marginTop={3}>
          {volume} m<sup>3</sup>
        </Typography>
      ) : (
        <TextFieldFormik
          name={`${name}.volume`}
          error={isError(`${name}.volume`)}
          variant="variant6"
          type="text"
          size="small"
          width={80}
          unit="m³"
          numeric
          debounced
          allowedChars={dimensionsRegExp}
        />
      )}
    </Labeled>
  );
  const selectTypeData = !isNil(itemType)
    ? shipmentItemTypesToSubTypes[itemType].map((type) => ({
        label: t(shipmentItemSubTypeLabels[type]),
        value: type,
      }))
    : [];
  return (
    <>
      <Accordion
        expanded={expanded}
        TransitionProps={transitionProps}
        backgroundColor={theme.palette.common.veryLightGrayAlt2}
        backgroundColorExpanded={theme.palette.common.lightGray}
        borderColor={fieldsNotFilled ? theme.palette.common.alertError : 'transparent'}
        className={clsx(fieldsNotFilled && 'Mui-error')}
      >
        {!isTemplateView && expanded && typeId === ShipmentItemSubType.Package && (
          <>
            <Box marginBottom={theme.spacing(1)}></Box>
          </>
        )}

        <AccordionSummary
          onDelete={onItemDelete}
          expandIcon={<DropdownIcon variant="dark" width={15} height={14} />}
          IconButtonProps={iconButtonProps}
          variant="variant1"
        >
          <Box display="flex" width={1}>
            <Box display="flex" alignItems="center" height={expanded ? 'fit-content' : 'auto'}>
              <ShipmentItemIcon itemType={itemType} className={classes.itemIcon} active />
              <Box width={82}>
                <Typography component="p" variant="body2" fontWeight="bold">
                  {itemTypeLabel}
                </Typography>
              </Box>
              <QuantityEditor
                min={1}
                step={1}
                initialValue={quantityId}
                valueChanged={quantityChanged}
                marginRight={8}
                className={classes.quantityEditor}
              />
            </Box>
            <Box display="flex" marginTop={expanded ? -1 : 1}>
              <Labeled
                text={t('SHIPMENT.ITEM.DETAILS.TYPE')}
                error={isError(`${name}.typeId`)}
                variant="variant2"
                width={134}
                className={classes.shipmentType}
                required={!isTemplateView}
                marginRight={3}
              >
                <SelectFormik
                  name={`${name}.typeId`}
                  error={isError(`${name}.typeId`)}
                  borderColor={theme.palette.common.lightGray}
                  background="opaque"
                  displayEmpty
                  fullWidth
                  value={!isNil(typeId) ? typeId : ''}
                  defaultLabel={t('SHIPMENT.ITEM.SELECT_TYPE')}
                  data={selectTypeData}
                />
              </Labeled>
              {verticalLine(expanded)}

              {typeId ? renderMeasurementField('length', defaultLengths[typeId]) : null}
              {typeId ? renderMeasurementField('width', defaultWidths[typeId]) : null}
              {typeId ? renderMeasurementField('height', defaultHeights[typeId]) : null}
              {typeId ? renderVolumeField(volumeIsEditable[typeId]) : null}
              {verticalLine(expanded)}
              <Labeled
                text={t('SHIPMENT.ITEM.DETAILS.WEIGHT_PC')}
                error={isError(`${name}.weight`)}
                variant="variant2"
                marginLeft={3}
                marginRight={2}
                required={!isTemplateView}
              >
                <TextFieldFormik
                  name={`${name}.weight`}
                  error={isError(`${name}.weight`)}
                  variant="variant6"
                  type="text"
                  size="small"
                  width={76}
                  unit="kg"
                  numeric
                  debounced
                  allowedChars={dimensionsRegExp}
                />
              </Labeled>
              <Labeled
                text={t('SHIPMENT.ITEM.DETAILS.TOTAL_WEIGHT')}
                error={isError(`${name}.totalWeight`)}
                variant="variant2"
                className={classes.totalWeight}
              >
                <Typography className={classes.smallText} variant="body2" marginTop={3}>
                  {totalWeight} kg
                </Typography>
              </Labeled>
              {verticalLine(expanded)}
              <Labeled
                text={t('SHIPMENT.ITEM.DETAILS.LDM')}
                error={isError(`${name}.ldm`)}
                variant="variant2"
                marginLeft={3}
                marginRight={2}
                tooltip={t('SHIPMENT.ITEM.DETAILS.LDM_TOOLTIP')}
                required={!isTemplateView && ldmIsRequired(typeId?.toString() ?? '')}
              >
                <TextFieldFormik
                  name={`${name}.ldm`}
                  error={isError(`${name}.ldm`)}
                  variant="variant6"
                  type="text"
                  size="small"
                  width={76}
                  numeric
                  debounced
                  allowedChars={dimensionsRegExp}
                />
              </Labeled>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box pb={8} className={classes.detailsRow}>
            <Box display="flex" alignItems="center" marginBottom={6} marginTop={3}>
              <Labeled
                text={t('SHIPMENT.ITEM.DETAILS.REFERENCE_NUMBER')}
                error={isError(`${name}.reference`)}
                variant="variant2"
                marginRight={6}
              >
                <TextFieldFormik
                  name={`${name}.reference`}
                  error={isError(`${name}.reference`)}
                  variant="variant6"
                  type="text"
                  size="small"
                  width={134}
                  debounced
                />
              </Labeled>
              <Labeled
                text={t('SHIPMENT.ITEM.DETAILS.PRODUCT_DESCRIPTION')}
                error={isError(`${name}.productDescription`)}
                variant="variant2"
              >
                <TextFieldFormik
                  name={`${name}.productDescription`}
                  error={isError(`${name}.productDescription`)}
                  variant="variant6"
                  type="text"
                  size="small"
                  debounced
                  className={classes.productDescription}
                />
              </Labeled>
            </Box>
            <Box display="flex" alignItems="center">
              <Switch
                name={`${name}.dangerousGoods`}
                label={`${t('SHIPMENT.ITEM.DETAILS.DANGEROUS_GOODS')}:`}
                className={classes.dangerousGoods}
                checked={dangerousGoods || false}
                onChange={onDangerousGoodsFlipped}
              />
              <Switch
                name={`${name}.coldFrozen`}
                label={`${t('SHIPMENT.ITEM.DETAILS.COLD_FROZEN')}:`}
                className={classes.coldFrozen}
                checked={coldFrozen || false}
              />
              <Switch
                name={`${name}.stackable`}
                label={`${t('SHIPMENT.ITEM.DETAILS.STACKABLE')}:`}
                className={classes.stackable}
                checked={stackable}
              />
              <Switch
                name={`${name}.delicateGoods`}
                label={`${t('SHIPMENT.ITEM.DETAILS.DELICATE')}:`}
                checked={delicateGoods || false}
              />
            </Box>
            {extraPanelVisible && (
              <Box display="flex" marginTop={4}>
                <Box width={256}>
                  {dangerousGoods && (
                    <>
                      <Box display="flex" marginBottom={3}>
                        <Labeled
                          text={t('SHIPMENT.ITEM.DETAILS.UN_NO')}
                          error={isError(`${name}.dangerousGoodsInfo[0].un`)}
                          variant="variant2"
                          tooltip={t('SHIPMENT.ITEM.DETAILS.UN_NO_TOOLTIP')}
                          marginRight={2}
                          required={!isTemplateView}
                        >
                          <TextFieldFormik
                            name={`${name}.dangerousGoodsInfo[0].un`}
                            error={isError(`${name}.dangerousGoodsInfo[0].un`)}
                            variant="variant6"
                            type="text"
                            size="small"
                            width={80}
                            placeholder={t('SHIPMENT.ITEM.DETAILS.UN')}
                            debounced
                          />
                        </Labeled>
                        <Labeled
                          text={t('SHIPMENT.ITEM.DETAILS.PG')}
                          error={isError(`${name}.dangerousGoodsInfo[0].pg`)}
                          variant="variant2"
                          tooltip={t('SHIPMENT.ITEM.DETAILS.PG_TOOLTIP')}
                          width={80}
                          marginRight={2}
                        >
                          {/*<SelectFormik
                            name={`${name}.dangerousGoodsInfo[0].pg`}
                            error={isError(`${name}.dangerousGoodsInfo[0].pg`)}
                            borderColor={theme.palette.common.lightGray}
                            background="opaque"
                            displayEmpty
                            fullWidth
                            value={(dangerousGoodsInfo && dangerousGoodsInfo[0]?.pg) || ''}
                            defaultValue=""
                            defaultLabel={t('SELECT')}
                            data={selectPgData}
                          />*/}
                        </Labeled>
                        <Labeled
                          text={t('SHIPMENT.ITEM.DETAILS.CLASS')}
                          error={isError(`${name}.dangerousGoodsInfo[0].classVal`)}
                          variant="variant2"
                          tooltip={t('SHIPMENT.ITEM.DETAILS.CLASS_TOOLTIP')}
                          width={80}
                          required
                        >
                          {/*<SelectFormik
                            name={`${name}.dangerousGoodsInfo[0].classVal`}
                            error={isError(`${name}.dangerousGoodsInfo[0].classVal`)}
                            borderColor={theme.palette.common.lightGray}
                            background="opaque"
                            displayEmpty
                            fullWidth
                            value={(dangerousGoodsInfo && dangerousGoodsInfo[0]?.classVal) || ''}
                            defaultValue=""
                            defaultLabel={t('SELECT')}
                            data={selectClassData}
                          />*/}
                        </Labeled>
                      </Box>
                      <Labeled
                        text={t('SHIPMENT.ITEM.DETAILS.PROPER_SHIPPING_NAME')}
                        error={isError(`${name}.dangerousGoodsInfo[0].properShippingName`)}
                        variant="variant2"
                        tooltip={t('SHIPMENT.ITEM.DETAILS.PROPER_SHIPPING_NAME_TOOLTIP')}
                      >
                        <TextFieldFormik
                          name={`${name}.dangerousGoodsInfo[0].properShippingName`}
                          error={isError(`${name}.dangerousGoodsInfo[0].properShippingName`)}
                          variant="variant6"
                          type="text"
                          size="small"
                          fullWidth
                          ellipsis
                          debounced
                        />
                      </Labeled>
                    </>
                  )}
                </Box>
                <Box width={168} marginLeft={16} display="flex">
                  {coldFrozen && (
                    <>
                      <Labeled
                        text={t('SHIPMENT.ITEM.DETAILS.MIN_TEMP')}
                        error={isError(`${name}.temperature`)}
                        variant="variant2"
                        tooltip={t('SHIPMENT.ITEM.DETAILS.MIN_TEMP_TOOLTIP')}
                        marginRight={2}
                      >
                        <TextFieldFormik
                          name={`${name}.temperature`}
                          error={isError(`${name}.temperature`)}
                          variant="variant6"
                          type="text"
                          size="small"
                          unit="°C"
                          width={80}
                          debounced
                          allowedChars={temperatureRegExp}
                        />
                      </Labeled>

                      <Labeled
                        text={t('SHIPMENT.ITEM.DETAILS.MAX_TEMP')}
                        error={isError(`${name}.temperatureMax`)}
                        variant="variant2"
                        tooltip={t('SHIPMENT.ITEM.DETAILS.MAX_TEMP_TOOLTIP')}
                      >
                        <TextFieldFormik
                          name={`${name}.temperatureMax`}
                          error={isError(`${name}.temperatureMax`)}
                          variant="variant6"
                          type="text"
                          size="small"
                          unit="°C"
                          width={80}
                          debounced
                          allowedChars={temperatureRegExp}
                        />
                      </Labeled>
                    </>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      {fieldsNotFilled && (
        <Typography customColor={theme.palette.common.alertError} variant="caption" marginTop={1}>
          {t('VALIDATION.SOME_REQUIRED_FIELDS_ARE_NOT_FILLED')}
        </Typography>
      )}
    </>
  );
};
