export default {
  WHY_LAASONE: 'Digitaliseeri oma logistikaoperatsioonid',
  OUR_OFFER: 'Teenused',
  DATEPICKER_PLACEHOLDER: 'dd.mm.yyyy',
  HOW_IT_WORKS: 'Meist',
  CASE_STUDIES: 'Klientide tagasiside',
  OUR_PEOPLE: 'Meie inimesed',
  CONTACT: 'Kontakt',
  CAREERS: 'Tule tööle!',
  BLOG: 'Blogi',
  WATCH_HOW_IT_WORKS: 'Vaata, kuidas see töötab',
  BOOK_A_DEMO: 'Broneeri demo',
  BOOK_A_DEMO_DESC:
    'Broneeri aeg ja vaata, kuidas LaasOne saab aidata sinu\n ettevõtte logistikat efektiivsemaks muuta.',
  HERO_TITLE: 'Sinu Digitaalne Logistikaosakond',
  HERO_DESC:
    'LaasOne automatiseerib väike- ja keskmise suurusega tööstusettevõtete logistika haldamise ja hinnastamise.',
  READ_MORE: 'Loe rohkem',
  IMPROVED_EFFICIENCY: 'Tööaja kokkuhoidu',
  IMPROVED_EFFICIENCY_DESC:
    'LaasOne automatiseerib logistika haldamise \nja hinnastamise ning vähendab Sinu \nmeeskonna logistikale kuluvat tööaega.',
  LOWER_TRANSPORT_COST: 'Klientidest säästavad',
  LOWER_TRANSPORT_COST_DESC:
    'Meil on ulatuslikud mahulepingud vedajatega, \nkeskmiselt 90% meie klientidest säästavad \noma logistikakuludelt!',
  SUCCESSFUL_SHIPMENTS: 'Edukalt täidetud tellimust',
  SUCCESSFUL_SHIPMENTS_DESC:
    'LaasOne teeb Sinu eest kõik läbirääkimised \nvedajatega ja lahendab ka vedudega \ntekkivad probleemid.',
  FAST_PRICES_TITLE: 'Kiired hinnapäringud erinevatelt vedajatelt',
  FAST_PRICES_1: 'Kuidas see käib?',
  FAST_PRICES_1_DESC:
    'Sisesta online-keskkonnas oma transpordisoov, meie saadame Sulle hinnapakkumise ning kinnitamisel läheb tellimus töösse.',
  FAST_PRICES_2: 'Kes on meie partnerid?',
  FAST_PRICES_2_DESC:
    'Teeme koostööd sadade vedajatega ning valime Sinu kauba transpordiks parima partneri ja tagame õigeaegse tarne.',
  ALL_YOUR_SHIPMENTS_TITLE: 'Ülevaade saadetistest ühel platvormil',
  ALL_YOUR_SHIPMENTS_1: 'Planeeri, broneeri ja jälgi ühest kohast.',
  ALL_YOUR_SHIPMENTS_1_DESC:
    'Tsentraalne haldus tagab kiire ülevaate ning analüüsi logistika hetkeseisu ja kulude kohta. Sul on pidev teave, millal kaup peale võetakse, kus see parajasti viibib ja millal kohale jõuab.',
  CREATING_A_SHIPMENT_TITLE: 'Vormista saadetis vaid paari minutiga',
  CREATING_A_SHIPMENT_1: 'Kaupade saatmine on imelihtne.',
  CREATING_A_SHIPMENT_1_DESC:
    'Sisesta kauba mõõdud, kaal ja kogused. Seejärel pealevõtu ja mahalaadimise aeg ja koht ning pane tellimus teele.\n\nKui vaja, saad tellimuse juurde lihtsasti lisada ka dokumente või kommentaare.',
  EASY_OVERVIEW_TITLE: 'Kogu saadetise info ühes kohas',
  EASY_OVERVIEW_1: 'Kõik vajalik kiirelt kätte',
  EASY_OVERVIEW_1_DESC:
    'Klõpsa lahti saadetise detailvaade ja näed kiirelt oma saadetise hetkeseisu ja dokumentatsiooni ning saad sealt samast kliendihalduriga läbi sõnumite suhelda.\n\nSellele infole saavad ligipääsu kõik su töökaaslased, kellele see vajalik on.',
  WAREHOUSE_OVERVIEW_TITLE: 'Asjakohane vaade laole',
  WAREHOUSE_OVERVIEW_1:
    'Parem logistika vajab paremat ülevaadet saabuvatest ja väljuvatest kaupadest',
  WAREHOUSE_OVERVIEW_1_DESC: 'Laovaate abil jõuab info ettevõtte laooperaatorile mõne sekundiga',
  SUPPORT_BY_LOGISTICS_TITLE: 'Professionaalsete logistikute tugi',
  SUPPORT_BY_LOGISTICS_1: 'LaasOne korraldab kogu partnerite haldamise',
  SUPPORT_BY_LOGISTICS_1_DESC:
    'LaasOne korraldab Sinu eest kõik läbirääkimised vedajatega ja lahendab ka vedudega tekkivad probleemid.\n\nSina saad keskenduda oma põhitegevusele ning toetavaid tegevusi nagu logistika haldavad oma ala professionaalid.',
  EAST_SOFTWARE_IMPLEMENTATION_TITLE: 'Lihtne tarkvara juurutamine',
  EAST_SOFTWARE_IMPLEMENTATION_1: 'LaasOne logistikaplatvormile üleminek käib kiirelt ja lihtsalt.',
  EAST_SOFTWARE_IMPLEMENTATION_1_DESC:
    'Meie kliendihaldur viib Teie meeskonnale läbi tasuta 45-minutilise koolituse, mis teeb viimsegi detaili hästi selgeks. Kui miski jääb segaseks, saab alati jooksvalt abi küsida.\n\nPS! LaasOne platvormi kasutamise eest eraldi tasu maksma ei pea.',
  WHAT_DO_WE_OFFER: 'Teenused',
  OFFER_1: 'Logistika ja transpordi haldamine',
  OFFER_1_DESC_1: 'Valime parimad partnerid ja tagame õigeaegse kohaletoimetamise',
  OFFER_1_DESC_2: 'Piiramatu arv partnereid kogu maailmas',
  OFFER_2: 'Kogu transporditeave ühel platvormil',
  OFFER_2_DESC_1: 'Ühtne tõhus transpordi andmete allikas',
  OFFER_2_DESC_2: 'Võimalus ühendada oma ERPi või muude süsteemidega',
  OFFER_3: 'Skaleeritav lahendus kliendile',
  OFFER_3_DESC_1: 'Kohaneb turu tõusude ja mõõnadega',
  OFFER_3_DESC_2: 'Sobiv lahendus vahemaast hoolimata',
  HOW_LAASONE_WORKS: 'Meist: Kuidas LaasOne töötab?',
  HOW_LAASONE_WORKS_DESC:
    'LaasOne platvorm ühendab tehnoloogia, infrastruktuuri ja ekspertiisi,\n et pakkuda reaalajas nähtavust ja kontrolli, ning\n palju paremat logistikahalduskogemust.',
  DID_YOU_KNOW:
    'Kas teadsite, et 45% kontoritööst saab tehnoloogia abil digitaliseerida? Digitaliseerige oma logistikaoperatsioonid LaasOne-iga.',
  LETS_DO_IT: 'Teeme ära!',
  PEOPLE_BEHIND_THE_STORY: 'Meie inimesed',
  WE_ARE_AROUND_EUROPE: 'Oleme Su lähedal',
  INVALID_NAME: 'Vigane nimi',
  INVALID_EMAIL: 'Vigane e-posti aadress',
  INVALID_PHONE: 'Vigane telefoninumber',
  INVALID_LINKEDIN: 'Vigne LinkedIni profiili link',
  TOO_LONG_MESSAGE: 'Sõnum on liiga pikk',
  GET_STARTED: 'Alusta',
  I_AGREE_WITH: 'Olen nõus',
  TERMS_AND_CONDITIONS: 'Tingimused',
  CAREER_AT_LAASONE: 'Tule tööle!',
  CAREERS_DESC:
    'LaasOne on ümber tegemas üle 1 triljoni dollari suuruse ekspedeerimise valdkonna mängureegleid, et muuta ülemaailmne kaubandus kõigile lihtsamaks. See on suur töö ja vastutus. Kas kõlab nagu midagi sulle?',
  CAREERS_NO_POSITIONS_DESC_1:
    'Hetkel pole meil kahjuks vabu ametikohti, aga hoia meie lehel silm peal, sest otsime tihti inimesi oma ägeda tiimiga liituma!',
  CAREERS_NO_POSITIONS_DESC_2:
    'Kui sa aga tunned, et just Sina oled meie tiimist täna puudu, siis kirjuta meile juba varem',
  MORE_INFORMATION_ABOUT_POSITION: 'Rohkem infot selle positsiooni kohta',
  SOUNDS_LIKE_YOU: 'Kõlab nagu Sina?',
  YOUR_NAME: 'Sinu nimi',
  YOUR_EMAIL: 'Sinu e-post',
  YOUR_LINKEDIN: 'Sinu LinkedIni URL',
  WHY_DO_YOU_WANT_TO_JOIN_US: 'Miks Sa tahad ühineda meie meeskonnaga?',
  APPLY_NOW: 'Kandideeri kohe',
  LATEST_POSTS: 'Viimased blogipostitused',
  ABOUT_US: 'MEIST',
  OUR_PARTNERS: 'Meie partnerid',
  CONTACT_US: 'Võta meiega ühendust',
  ANY_QUESTIONS: 'Tekkis küsimusi?',
  NICE: 'Kena!',
  YOU_ARE_ALL_SIGNED_UP: 'Olete registreerunud demoks!',
  WE_WILL_CONTACT_YOU:
    'Me võtame Sinuga teiega ühendust esimesel võimalusel, et kokkuleppida aeg ja kuupäev demo tegemiseks.',
  LOOKING_FORWARD_MEETING_YOU: 'Ootame Sinuga kohtumist!',
  WE_GOT_YOUR_INFORMATION: 'Saime Sinu info kätte!',
  WE_ARE_HAPPY_THAT_YOU_WROTE:
    'Meil on väga hea meel, et Sa kirjutasid meile! Võtame Sinuga ühendust esimesel võimalusel.',
  THIS_WEBSITE_USES_COOKIES: 'See lehekülg kasutab küpsiseid',
  WE_USE_COOKIES:
    'Kasutame küpsiseid. Võite küpsistest igal hetkel loobuda, kui muudate enda kasutuses oleva seadme brauseri seadeid ning kustutate sinna salvestatud küpsised.',
  REJECT_COOKIES: 'Keeldu küpsistest',
  ALLOW_COOKIES: 'Luba küpsised',
  I_WOULD_LIKE_TO_RECEIVE_NEWSLETTER: 'Sooviksin saada LaasOne uudiskirja',
  SO_FAR: 'Senini',
  COST_REDUCTION: 'Kulude alandamist',
  PRIVACY_POLICY: 'Privaatsuspoliitika',
  COOKIE_POLICY_FILE_NAME: 'Küpsiste avaldus',
  COOKIE_NOTICE: 'Küpsiste teade',
  COOKIE_PRE_TEXT: 'Teie nõusolek kehtib järgmistele domeenidele:',
  COOKIE_UPDATED: 'Küpsiste avaldus viimati uuendatud: ',
  WHAT_ARE_COOKIES_HEADING: 'Mis on küpsis?',
  WHAT_ARE_COOKIES_TEXT:
    'Küpsis on väike andmefail, mis on salvestatud teie arvutisse, tahvelarvutisse või mobiiltelefoni. Küpsis ei ole programm, mis võib sisaldada kahjulikke programme või viiruseid.',
  COOKIES_ON_THIS_WEBSITE_HEADING: 'Kuidas / miks koduleht kasutab küpsiseid',
  COOKIES_ON_THIS_WEBSITE_TEXT:
    'Küpsised on vajalikud kodulehe toimimiseks. Küpsised aitavad meil saada ülevaate teie külastusest kodulehel, et saaksime pidevalt optimeerida ja kohandada kodulehekülge vastavalt teie vajadustele ja huvidele. Küpsised mäletavad näiteks seda, mida lisasite oma ostukorvi, kui lehte varem külastasite, kui logisite sisse ja milliseid keeli ja valuutat soovite kodulehel kuvada. Kasutame küpsiseid ka selleks, et suunata teile oma reklaame teistel kodulehtedel. Väga üldisel tasemel kasutatakse küpsiseid meie teenuste osana, et näidata teile võimalikult olulist sisu.',
  COOKIES_HOW_LONG_HEADING: 'Kui kaua küpsised hoitakse?',
  COOKIES_HOW_LONG_TEXT:
    'Aeg, kui kaua küpsiseid teie seadmes hoitakse võib varieeruda. Nende planeeritud aegumise aeg arvutatakse viimasest kuupäevast, mil te kodulehte külastasite. Kui küpsised aeguvad, kustutatakse need automaatselt.',
  COOKIES_DECLINE_HEADING: 'Nii saate oma küpsiseid tagasi lükata või kustutada',
  COOKIES_DECLINE_TEXT:
    'Küpsiseid saate oma arvutis, tahvelarvutis või telefonis alati keelata, muutes lehitseja seadeid. Nende seadete asukoht sõltub kasutatava lehitseja tüübist. Kui muudate seadeid, peate teadma, et te ei saa kasutada mõningaid funktsioone ja teenuseid, kuna need sõltuvad kodulehe võimest teie valikuid meeles pidada.',
  COOKIES_DELETE_HEADING: 'Küpsiste kustutamine',
  COOKIES_DELETE_TEXT:
    'Võite kustutada küpsised, mida olete varem lubanud. Kui kasutate arvutit, milles on lehitseja värskeim versioon, saate oma küpsised kustutada järgmiste kiirklahvidega: CTRL + SHIFT + Delete.',
  COOKIES_DELETE_TEXT2:
    "Kui kiirklahvid ei tööta ja / või kasutate Apple'i arvutit, peate välja selgitama, millist lehitsejat te kasutate ja klõpsama seejärel asjakohasel lingil:",
  COOKIES_DELETE_TEXT3:
    'Pidage meeles: Kui kasutate mitut erinevat lehitsejat, peate küpsised kustutama neis kõikides.',
  COOKIES_QUESTIONS_HEADING: 'Teil on küsimusi?',
  COOKIES_QUESTIONS_TEXT:
    'Kui teil on küsimusi või kommentaare seoses antud teabega ja / või isikuandmete töötlemisega, võtke meiega ühendust.',

  NOT_FOUND_HEADING: 'Oh ei!',
  NOT_FOUND_DESC: 'Otsitavat lehte pole olemas.',
  NOT_FOUND_DESC_2: 'Veakood: 404',
  GO_TO_HOMEPAGE: 'Mine avalehele',
  FORM_SOMETHING_WENT_WRONG: 'Midagi läks valesti! Palun proovi uuesti.',
  COUNTRY: 'Asukoha riik',
  PHONE_NUMBER: 'Telefoninumber',
  ANNUAL_FREIGHT_SHIPMENTS: 'Aastane kaubasaadetiste maht',
  EMAIL: 'E-post',
  COMPANY_NAME: 'Ettevõtte nimi',
  FIRST_NAME: 'Eesnimi',
  LAST_NAME: 'Perekonnanimi',
  LOGIN: 'Logi sisse',

  // BLOG

  PUBLISHED: 'Avaldatud',
  AUTHOR: 'Autor',
  TAGS: 'Sildid',
  READ_MORE_ABOUT_LAASONE: 'Lisateavet LaasOne kohta',
  LOAD_MORE_POSTS: 'Laadige rohkem postitusi',
  GO_TO_LAASONE_HOMEPAGE: 'Minge LaasOne kodulehele',

  // CASE STUDIES

  READ_MORE_ABOUT: 'Loe lähemalt',

  CRYSTALSPACE_TEXT:
    'LaasOne organiseeris meie kõrgtehnoloogiliste kaamerate logistika Eestist USAsse. Muuhulgas oli nende korraldada kogu eksporti puudutav paberimajandus, mis on kosmosetehnoloogia logistikas just kõige keerukam pool.',
  CRYSTALSPACE_READ_MORE_TEXT: 'kuidas Crystalspace kaamerad Kuu peale jõuavad.',
  CRYSTALSPACE_AUTHOR: 'Jaan Viru, Crystalspace OÜ asutaja ja tehnoloogiajuht',

  RADIUS_TEXT:
    'Minule kui juhile on logistikaplatvormi puhul oluline tsentraalne haldus, mis tagab kiire ülevaate ning analüüsi logistika hetkeseisu ja kulude kohta. Nii saame keskenduda oma põhitegevusele ning toetavaid tegevusi haldavad oma ala professionaalid nagu LaasOne meeskond.',
  RADIUS_READ_MORE_TEXT: 'mida ootab Radius Machining tegevjuht kaasaegselt logistikasektorilt.',
  RADIUS_AUTHOR: 'Ahti Talts, Radius Machining OÜ tegevjuht',

  POHJALA_TEXT:
    'Meile koheselt meeldis LaasOne platvormi ja tellimiskeskkonna lihtsus. Sealt on kõigil osapooltel mugav jälgida, kus tooraine parajasti viibib, mis kell jõuab jne. Lisaks hindan väga nende personaalset teenindust, mida suured ettevõtted enamasti pakkuda ei suuda.',
  POHJALA_READ_MORE_TEXT:
    'miks otsustas Põhjala pruulikoda võtta kasutusele LaasOne logistikaplatvormi.',
  POHJALA_AUTHOR: 'Rait Kulli, Põhjala pruulikoja tootmisjuht',

  FINNROTOR_TEXT:
    'Finn-Rotor Estonia OÜ kaupade saatmisest üle maailma: "Tänu LaasOne-ile saame hoida kõiki oma saadetisi ühes lihtsasti kasutatavas süsteemis. LaasOne aitab meil kokku hoida aega ja säästa raha transpordikuludelt.',
  FINNROTOR_READ_MORE_TEXT: '',
  FINNROTOR_AUTHOR: 'Matti Övermark, CEO',

  MERREM_TEXT:
    'Logistika on muutumas meie pidevalt kasvavas ettevõttes järjest olulisemaks. LaasOne on aidanud muuta meie logistika kergesti hallatavaks ja kuluefektiivseks. Nad hoiavad meie logistikakulud madalal ja tänu sellele on meie ettevõte kasvanud igal aastal pea 20 protsenti.',
  MERREM_READ_MORE_TEXT: '',
  MERREM_AUTHOR: 'Alar Künnapuu, CEO',

  // TEAM DATA
  VILLE_PELTOLA_TITLE: 'Tegevjuht',
  JUSSI_PELTOLA_TITLE: 'Äriarenduse juht',
  LAAS_KASK_TITLE: 'Operatsioonide juht',
  TALVAR_NURK_TITLE: 'Tarkvaraarendus',
  MARGUS_MAJOROV_TITLE: 'Logistika spetsialist',
  LAURA_MIKK_TITLE: 'Logistika spetsialist',
  INDREK_RAHULA_TITLE: 'Logistika spetsialist',
  VAINO_MIIL_TITLE: 'Müük',

  // METADATA

  PAGE_TITLE: 'LaasOne – Your Digital Logistics Department',
  PAGE_DESCRIPTION:
    'LaasOne automates logistics management and pricing for small and mid-sized industrial enterprises. 50% more efficient, 10% lower transport costs, support by logistics professionals.',

  // ELEMENTS IDS

  ID_BOOK_A_DEMO_BTN: 'bookademo_et_button',
  ID_BOOK_A_DEMO_BTN_LABEL: 'bookademo_et_label',

  ID_LOGIN_BTN: 'login_et_button',
  ID_LOGIN_BTN_LABEL: 'login_et_label',

  //ORDER FORMS
  ORDER_FORM: {
    TITLE: 'Kohene hinnastus & tellimine ettevõtetele',
    FROM: 'Pealelaadimine',
    TO: 'Mahalaadimine',
    FORM_TO_PLACEHOLDER: 'Riik, linn ja postikood',
    SUBMIT: 'Otsi hindu',
    CARGO: 'Saadetis',
    WEIGHT: 'Kaal',
    WEIGHT_PLACEHOLDER: 'KG',
    AMOUNT: 'Kogus',
    PACKAGE: 'Pakk',
    PALLET: 'Alus',
    MEASURES: 'Mõõdud (cm)',
    LENGTH_PLACEHOLDER: 'Pikkus',
    WIDTH_PLACEHOLDER: 'Laius',
    HEIGHT_PLACEHOLDER: 'Kõrgus',
    NO_PRICES_FOUND:
      'Me ei leidnud hetkel soovitud marsruudi jaoks hinda. \n Palun saada meile hinnapäring ja vastame sellele 24 tunni jooksul!',
    SEND_QUOTE: 'SAADA HINNAPÄRING',
    ORDER: 'TELLI',
    PRICE: 'HIND',
    EXCL_VAT: 'KM-ta',
    SEND_QUOTATION_TITLE: 'Saada hinnapäring',
    CANCEL_QUOTATION: 'Tühista',
    SEND_QUOTATION: 'SAADA',
    SEND_QUOTATION_INFORMATION:
      'Jäta meile enda kontaktandmed ja me leiame Sulle sobiva lahenduse ja hinna.',
    CONTACT_COMPANY: 'Ettevõtte nimi',
    CONTACT_NAME: 'Kontakt',
    CONTACT_EMAIL: 'Email',
    CONTACT_PHONE: 'Telefoninumber',
    YOUR_CONTACT: 'Kontaktisik',
    CLOSE: 'Sulge',
    QUOTATION_SENT: 'Saime hinnapäringu kätte!',
  },
  SELECT_COUNTRY: 'Vali riik',
  TRANSPORT: {
    TITLE: 'Täida oma kliendi käest saadud kliendikood',
    CUSTOMER_NOT_FOUND: 'Ettevõtet ei leitud',
    REG_CODE: 'Registrikood',
    ADDRESSES: 'Aadress',
    BTN_ORDER: 'TELLI TRANSPORT',
  },
  ORDER: {
    TITLE: 'Telli transport',
    ITEMS: 'Transporditav kaup',
    ADDRESSES: 'Lisa aadressid',
    DATES: 'Lisa peale- ja mahalaadimiskuupäevad',
    PRICE: 'Teie saadetise hind',
    SUBMIT: 'Telli',
    YOUR_INFORMATION: 'Sisesta enda ettevõte informatsioon',
    ADDRESS: {
      COMPANY_INFO: 'Aadress ja kontakt',
      COMPANY_REG: 'Reg nr.',
    },
    SEND_MODAL_TITLE: 'Vormista tellimus ...',
    CANCEL: 'Tühista',
    CLOSE: 'Sulge',
    SEND_MODAL_TEXT: 'Kas oled veendunud sisestatud info õigsuses?',
    ORDER_SENT: 'Teie tellimus on edukalt saadetud! Võtame Teiega peagi ühendust!',
    ADD_REFERENCE: 'Lisa viitenumber',
    REFERENCE: 'Viitenumber',
    ADDRESS_TOOLTIP:
      'Please insert correct e-mail addresses to receive notifications about the order',
  },
  PRICE_REQUEST: {
    DAYS_DELIVERY: 'Tarneaeg',
    SAME_DAY_DELIVERY: 'Sama päeva tarne',
    ON_TIME_DELIVERY: 'Fikseeritud ajaga tarne',
    THIS_OPTION_IS_CHOSEN: 'Valitud hind',
  },
  SHIPMENT: {
    I_NEED_PICKUP_WITH_A_TAIL_LIFT_TRUCK: 'Soovin korjet tagaluugiga autoga',
    YOUR_SHIPMENTS_PRICE: 'Tellimuse maksumus',
    YOUR_SHIPMENTS_PRICE_DESC:
      'Pakutud hinnad on indikatiivsed ja võivad muutuda, kui tegelik kaubakogus muutub',
    NO_PRICES:
      'Me ei leidnud Teie saadetise sobivat hinda, kuid saatke meile hinnapäring ja püüame leida Teile sobiva hinna.',
    AUTOMATIC_PRICES_IN_PROGRESS:
      'Teeme kõvasti tööd, et leida Teie sisestatud toodetele ja marsruutidele parimad hinnad!',
    EARLIEST_PICKUP_DATE: 'Varaseim pealevõtmise kuupäev',
    LATEST_PICKUP_DATE: 'Hiliseim pealevõtmise kuupäev',
    EARLIEST_DELIVERY_DATE: 'Varaseim kohaleviimise kuupäev',
    LATEST_DELIVERY_DATE: 'Hiliseim kohaleviimise kuupäev',
    FIXED_PICKUP_DATE: 'Fikseeritud pealevõtmise kuupäev',
    FIXED_DELIVERY_DATE: 'Fikseeritud kohaleviimise kuupäev',
    EARLIEST_PICKUP_TIME: 'Varaseim pealelaadimise aeg',
    LATEST_PICKUP_TIME: 'Hiliseim pealelaadimise aeg',
    EARLIEST_DELIVERY_TIME: 'Varaseim mahalaadimisaeg',
    LATEST_DELIVERY_TIME: 'Hiliseim mahalaadimisaeg',
    FIXED_PICKUP_TIME: 'Fikseeritud korjeaeg',
    FIXED_DELIVERY_TIME: 'Fikseeritud tarneaeg',
    PLEASE_CHECK_YOUR_SHIPMENT_INFORMATION_BEFORE_CONFIRMING:
      'Enne kinnitamist kontrollige sisestatud info üle',
    DATES: {
      ADD_PICKUP_DELIVERY_DATES: 'Lisage peale- ja mahalaadimise kuupäevad',
      PICKUP_DATES: 'Korje kuupäev',
      DELIVERY_DATES: 'Kohaleveo kuupäev',
      PICK_PICKUP_DATES: 'Lisa varaseim ja hiliseim korjekuupäev',
      PICK_DELIVERY_DATES: 'Lisa varaseim ja hiliseim tarnekuupäev',
      PICK_PICKUP_FIXED_DATE: 'Lisa fikseeritud korjekuupäev',
      PICK_DELIVERY_FIXED_DATE: 'Lisa fikseeritud tarnekuupäev',
    },
    ADDRESSES: {
      CLIENT_CODE: 'Kliendikood',
      ADD_ADDRESS: 'Lisa aaadress',
      UPDATE_ADDRESS: 'Värskenda aadressit',
      ADD_ROUTE_ADDRESSES: 'Lisa marsruut',
      REQUIRED_FIELDS_ARE_MARKED_WITH: 'Kohustuslikud väljad on tähistatud tärniga',
      WHERE_TO_PICK_UP_FROM: 'Kust tuleb saadetis peale võtta?',
      WHERE_TO_DELIVER: 'Kuhu tuleb saadetis viia?',
      RECENTLY_USED_ADDRESSES: 'Varasemalt kasutatud aadressid',
      CHOOSE_FROM_ADDRESS_BOOK: 'Vali aadressiraamatust',
      ADD_MANUALLY: 'Lisa käsitsi',
      CLEAR_ALL_FIELDS: 'Tühjenda kõik väljad',
      SAVE_THIS_ADDRESS: 'Salvesta aadress:',
      NAME_THIS_ADDRESS: 'Aadressi nimetus aadressiraamatus',
      SENDER_AND_DELIVERY_ADDRESSES: 'Saatja ja saaja aadressid',
      TYPE_HERE_THE_ADDRESS_AND_SELECT_IT_FROM_GOOGLE_RESULTS_OR_FROM_ADDRESS_BOOK:
        'Sisestage aadress ja leidke see Google otsingumootorist või aadressiraamatust',
      COUNTRY: 'Riik',
      CITY: 'Linn',
      POSTCODE: 'Postikood',
      COMPANY_NAME: 'Ettevõtte nimi',
      CONTACT_PERSON: 'Kontaktisik',
      CONTACT_NUMBER: 'Telefoninumber',
      EMAIL: 'E-mail',
      REFERENCE: 'Viitenumber',
      LOADING_REFERENCE: 'Pealelaadimise viitenumber',
      LOADING_REFERENCE_TOOLTIP: 'Korjeaadressile/hoonele vms vastav number.',
      UNLOADING_REFERENCE: 'Mahalaadimise viitenumber',
      UNLOADING_REFERENCE_TOOLTIP: 'Tarneaadressile/hoonele vms vastav number.',
      ADD_AN_ALTERNATIVE_ADDRESS: 'Alternatiivne mahalaadimise aadress',
      ADDRESS_DETAILS: 'Aadressi detailid',
      ADDRESS: 'Aadress',
      ADD_MORE_INFORMATION: 'Lisateave',
      CLOSE_MORE_INFORMATION: 'Sulge lisateave',
      SENDER: {
        INFORMATION: 'Saatja informatsioon',
        ADDRESS: 'Saatja aadress',
        CONTACT_PERSON: 'Saatja kontaktisik',
        ADDRESS_DETAILS: 'Korje aadressi lisainfo',
        ADDRESS_DETAILS_TOOLTIP:
          'Lisainfo kauba kättesaamise kohta (näiteks hoone küljelt, tagauksest, terminalist jne)',
        ALTERNATIVE_ADDRESS: 'Alternatiivne pealelaadimise aadress',
        ALTERNATIVE_CONTACT_PERSON: 'Alternatiivse pealelaadimise kontaktisik',
        ALTERNATIVE_ADDRESS_DETAILS: 'Alternatiivse pealelaadimise aadress',
        ADD_AN_ALTERNATIVE_ADDRESS: 'Lisa alternatiivse pealelaadimise aadress',
      },
      COMPANY: {
        ADDRESS: 'Ettevõtte aadress',
      },
      RECEIVER: {
        INFORMATION: 'Saaja informatsioon',
        ADDRESS: 'Saaja aadress',
        CONTACT_PERSON: 'Saaja kontaktisik',
        ADDRESS_DETAILS: 'Tarne aadressi lisainfo',
        ADDRESS_DETAILS_TOOLTIP:
          'Lisainfo kauba kohaletoimetamise kohta (näiteks siseneda hoone küljelt, tagauksest, viia terminali jne)',
        ALTERNATIVE_ADDRESS: 'Alternatiivne kaubasaaja aardess',
        ALTERNATIVE_CONTACT_PERSON: 'Alternatiivse kaubasaaja kontaktisik',
        ALTERNATIVE_ADDRESS_DETAILS: 'Alternatiivse kaubasaaja aadressi detailid',
        ADD_AN_ALTERNATIVE_ADDRESS: 'Lisa alternatiivse kaubasaaja aadress',
      },
    },
    ADD_ITEMS_TO_YOUR_SHIPMENT: 'Lisa saadetise informatsioon',
    CLICK_ON_THE_ITEM_YOU_WANT_TO_ADD: 'Klõpsake üksustel, mida soovite lisada',
    ITEMS_ADDED: 'Üksused lisatud',
    I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK: 'Vajan tarnet tagaluuk-autoga',
    ITEM: {
      TOTAL: 'Kokku',
      SELECT_TYPE: 'Valige pakitüüp',
      DETAILS: {
        PC: 'Tk',
        TYPE: 'Pakitüüp',
        LENGTH: 'Pikkus',
        LENGTH_TOOLTIP: 'Pikkus on valitud kaubatüübi jaoks fikseeritud',
        WIDTH: 'Laius',
        WIDTH_TOOLTIP: 'Laius on valitud kaubatüübi jaoks fikseeritud',
        HEIGHT: 'Kõrgus',
        VOLUME: 'Maht',
        WEIGHT_PC: 'Kaal/tk',
        TOTAL_WEIGHT: 'Kogu kaal',
        LDM: 'LDM',
        LDM_TOOLTIP:
          'Laadimismeetrid (haagise pikkuse jooksvad meetrid täislaiuses ja kõrguses). See arvutatakse automaatselt pikkuse, laiuse ja koguse põhjal, kuid vajadusel saate kirjutada ka erineva väärtuse.',
        REFERENCE_NUMBER: 'Viitenumber',
        PRODUCT_DESCRIPTION: 'Toote kirjeldus',
        UN_NO: 'UN nr',
        UN_NO_TOOLTIP: 'Number, mis identifitseerib ohtlikke kaupu, ohtlikke aineid ja esemeid',
        UN: 'UN',
        UN_CODE: 'UN kood',
        PG: 'PG (pakendirühm)',
        PG_TOOLTIP: 'Ohtlikud kaubad jaotatakse 3 pakendirühma vastavalt nende ohtlikkuse astmele',
        CLASS: 'Klass',
        CLASS_TOOLTIP: 'Ohtlike kaupade klassifikatsiooninumbrid',
        DANGEROUS_GOODS: 'Ohtlikud kaubad',
        COLD_FROZEN: 'Külmutatud',
        STACKABLE: 'Virnastatav',
        DELICATE: 'Õrn',
        MIN_TEMP: 'Min temp',
        MIN_TEMP_TOOLTIP: 'Kauba minimaalne temperatuur, mida transpordiks vajab',
        MAX_TEMP: 'Max temp',
        MAX_TEMP_TOOLTIP: 'Kauba maksimaalne temperatuur, mida transpordiks vajab',
        PROPER_SHIPPING_NAME: 'Nõuetele vastav kauba nimetus',
        PROPER_SHIPPING_NAME_TOOLTIP:
          'Nimetus ohtlike kaupade ohtlikkuse ja koostise kirjeldamiseks',
        PROPER_SHIPPING_NAME_PLACEHOLDER: 'Must pulber, kokkusurutud või püssirohi',
        PACKAGE_INFO: 'Kui kaup on virnastatav, märkige väljale "Pikkus" pikima külje mõõt.',
      },
      TYPE: {
        PALLET: 'Alus',
        PACKAGE: 'Pakk',
        TRUCK: 'Veoauto',
        OTHER: 'LDM',
        CONTAINER: 'Konteiner',
      },
      SUBTYPE: {
        EUR_PALLET: 'EUR alus',
        FIN_PALLET: 'FIN alus',
        PALLET: 'Alus',
        PACKAGE: 'Pakk',
        BOX: 'Kast',
        FULL_TRUCK_LOAD: 'Täiskoorem',
        LONG_PALLET: 'Pikk alus',
        HALF_PALLET: 'Poolik alus',
        LDM: 'LDM',
        CONTAINER_20_DC: '20’DC',
        CONTAINER_40_DC: '40’DC',
        CONTAINER_40_HC: '40’HC',
        CONTAINER_OTHER: 'Muu',
      },
      CLASS: {
        EXPLOSIVES: 'Lõhkeained',
        GASES: 'Gaasid',
        FLAMMABLE_LIQUIDS: 'Tuleohtlikud vedelikud',
        FLAMMABLE_SOLIDS: 'Tuleohtlikud tahked ained',
        OXIDIZERS: 'Oksüdeerijad',
        TOXIC_SUBSTANCES: 'Mürgised ained',
        RADIOACTIVE: 'Radioaktiivsed ained',
        CORROSIVES: 'Söövitavad ained',
        MISCELLANOUS: 'Segalaadi ained',
      },
      PG: {
        PG1: 'Packing Group I: kõrge oht',
        PG2: 'Packing Group II: mõõdukas oht',
        PG3: 'Packing Group III: madal oht',
      },
    },
  },
  VALIDATION: {
    POSTCODE_IS_REQUIRED: 'Sisesta täpsem aadress',
  },
  ADD_DOCUMENTS_TITLE: 'Lisa dokumendid',
  DROPZONE: {
    DRAG_AND_DROP: 'Lohista failid siia või',
    CHOOSE_FROM_COMPUTER: 'vali failid oma arvutist',
    SUPPORTED_FILES: 'Toetatud failitüübid: PDF, PNG, JPG, JPEG',
    SUPPORTED_FILES_XLS: 'Toetatud failitüübid: XLSX',
    ERROR: 'Faili “{0}” ei saa laadida, sest tema formaat ei ole toetatud',
  },
  SALES_EST: 'Müük Baltikum',
  SALES_FI: 'Müük Skandinaavia',
  TITLE_ADMINISTRATION: 'Finants',
  TITLE_GENERAL: 'Üldine',
  TORMMETALL_TEXT:
    'Olen äärmiselt rahul LaasOne’i professionaalse suhtumise ja teenuse kvaliteediga. Nende meeskond on sõbralik, abivalmis ning ehk mis kõige tähtsam – kiire reageerimisvõimega, mis teeb koostöö nendega sujuvaks ja usaldusväärseks. LaasOne on suurepärane näide ettevõttest, mis on digitaliseerumisega täiesti sammu pidanud. Soovitan LaasOne’i kindlasti kõigile, kes otsivad tõhusat ja usaldusväärset logistikateenust.',
  TORMMETALL_READ_MORE_TEXT: '',
  TORMMETALL_AUTHOR: 'Sander Poljakov, Logistik',
  KONETALO_READ_MORE_TEXT: '',
  KONETALO_TEXT:
    'LaasOne-ga saime logistika paremini hallatud ning kulud kontrolli alla. Teenus, mida tõepoolest julgeme soovitada!',
  KONETALO_AUTHOR: 'Timo Peltola, CEO',
  HAPPY_BIRTHDAY: 'LaasOne on tänasest nüüd Laas Fifty.',
  HAPPY_BIRTHDAY2: 'Meie hea kolleeg Laas Kask saab 50 aastaseks!',
  BTN_CLOSE: 'Sulge',
  COMPANY: {
    OFFICES: 'Kontorite ja ladude aadressid',
  },
  PRIMARY_CONTACT: 'Teie kontakt',
};
