export default {
  WHY_LAASONE: 'Digitise your logistics operations',
  OUR_OFFER: 'Our offer',
  DATEPICKER_PLACEHOLDER: 'dd.mm.yyyy',
  HOW_IT_WORKS: 'How it works?',
  CASE_STUDIES: 'Client testimonials',
  OUR_PEOPLE: 'Our people',
  CONTACT: 'Contact',
  CAREERS: 'Careers',
  BLOG: 'Blog',
  WATCH_HOW_IT_WORKS: 'Watch, how it works',
  BOOK_A_DEMO: 'Book a demo',
  BOOK_A_DEMO_DESC:
    'Schedule a demo to see how LaasOne platform can help you\n manage your logistics and transport.',
  HERO_TITLE: 'Your digital logistics department, the only ONE you need',
  HERO_DESC:
    'LaasOne automates logistics management and pricing for small and mid-sized industrial enterprises.',
  READ_MORE: 'Read more',
  IMPROVED_EFFICIENCY: 'More efficient',
  IMPROVED_EFFICIENCY_DESC:
    'LaasOne automates logistics pricing and \nmanagement, reducing the time your team \nspends on logistics.',
  LOWER_TRANSPORT_COST: 'of customers are saving',
  LOWER_TRANSPORT_COST_DESC:
    'We have extensive volume contracts with \nshipping companies, so on average 90% of our \ncustomers have recorded savings in logistics costs!',
  SUCCESSFUL_SHIPMENTS: 'Successful shipments',
  SUCCESSFUL_SHIPMENTS_DESC:
    'LaasOne conducts negotiations with shipping \ncompanies for you and solves any shipping \nrelated issues.',
  FAST_PRICES_TITLE: 'Fast price requests from various shippers',
  FAST_PRICES_1: 'How does it work?',
  FAST_PRICES_1_DESC:
    'Enter your transportation needs in our online platform, we will send you a price offer and after your confirmation the order will be filled.',
  FAST_PRICES_2: 'Who are our partners?',
  FAST_PRICES_2_DESC:
    'We work with hundreds of shipping companies and will choose the best partner to suit your transportation needs, ensuring on-time delivery.',
  ALL_YOUR_SHIPMENTS_TITLE: 'All your shipments on one platform',
  ALL_YOUR_SHIPMENTS_1: 'Plan, book and track on one platform.',
  ALL_YOUR_SHIPMENTS_1_DESC:
    'Central logistics management guarantees a fast overview and analysis of the cost and the current state of logistics. You are always informed on the shipment’s pick-up, location and arrival time.',
  CREATING_A_SHIPMENT_TITLE: 'Creating a shipment only takes a few minutes',
  CREATING_A_SHIPMENT_1: 'Sending goods is easy.',
  CREATING_A_SHIPMENT_1_DESC:
    "Insert the shipment's measurements, weight and volume. Add locations and time for pick-up and drop-off and complete the order.\n\nIf necessary, you can easily add documents or comments to your order.",
  EASY_OVERVIEW_TITLE: 'Easy overview of your shipment details',
  EASY_OVERVIEW_1: 'All information quickly accessible.',
  EASY_OVERVIEW_1_DESC:
    'Open the detail view to get an overview of the status and the documentation of your shipment. You can also directly message our logistics specialist.\n\nThe shipping information can be accessed by all your co-workers who need it.',
  WAREHOUSE_OVERVIEW_TITLE: 'Relevant view for the warehouse',
  WAREHOUSE_OVERVIEW_1: 'Better logistics need better overview of inbound and outbound shipments',
  WAREHOUSE_OVERVIEW_1_DESC:
    'With the warehouse view information reaches the warehouse personnel in a few seconds',
  SUPPORT_BY_LOGISTICS_TITLE: 'Support by logistics professionals',
  SUPPORT_BY_LOGISTICS_1: 'LaasOne takes care of everything regarding deviation management.',
  SUPPORT_BY_LOGISTICS_1_DESC:
    'We conduct negotiations with the shipping companies for you and solve any shipping related issues.\n\nYou can focus on your business and all supporting tasks like logistics are carried out by professionals of their field.',
  EAST_SOFTWARE_IMPLEMENTATION_TITLE: 'Easy software implementation',
  EAST_SOFTWARE_IMPLEMENTATION_1: 'Transitioning to LaasOne logistics platform is quick and easy.',
  EAST_SOFTWARE_IMPLEMENTATION_1_DESC:
    'Our logistics specialist will conduct a free 45-minute training session for your team, thoroughly explaining even the minor details. If something needs further clarification, we are always here to help you.\n\nUsing LaasOne logistics platform is free of charge.',
  WHAT_DO_WE_OFFER: 'What do we offer?',
  OFFER_1: 'Managing your logistics and transport',
  OFFER_1_DESC_1: 'We choose the best partners and ensure timely delivery',
  OFFER_1_DESC_2: 'Limitless amount of partners globally, online 24/7',
  OFFER_2: 'All transport information in one platform',
  OFFER_2_DESC_1: 'A single efficient source of shipping data on one screen',
  OFFER_2_DESC_2: 'Possibility to connect with your ERP or other system',
  OFFER_3: 'Scalable solution for the customer',
  OFFER_3_DESC_1: 'Adapt to market ups and downs',
  OFFER_3_DESC_2: 'A suitable solution regardless the distance',
  HOW_LAASONE_WORKS: 'How LaasOne works?',
  HOW_LAASONE_WORKS_DESC:
    'LaasOne platform brings together technology, infrastructure, and\n expertise to deliver real-time visibility and control, and a far better\n logistics management experience.',
  DID_YOU_KNOW:
    'Did you know that 45% of office work can be automated using  technology? Automate your logistics operations with LaasOne.',
  LETS_DO_IT: "Let's do it!",
  PEOPLE_BEHIND_THE_STORY: 'People behind the story',
  WE_ARE_AROUND_EUROPE: 'We are near You',
  INVALID_NAME: 'Invalid name',
  INVALID_EMAIL: 'Invalid e-mail address',
  INVALID_PHONE: 'Invalid phone number',
  INVALID_LINKEDIN: 'Invalid LinkedIn profile link',
  TOO_LONG_MESSAGE: 'Message too long',
  GET_STARTED: 'Get started',
  I_AGREE_WITH: 'I agree with',
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  CAREER_AT_LAASONE: 'Career at LaasOne',
  CAREERS_DESC:
    'At LaasOne, we’re reinventing the over $1-trillion freight-forwarding industry to make global trade easier for all. It’s a big job, with huge upside. Sounds like you?',
  CAREERS_NO_POSITIONS_DESC_1:
    'Sorry, we don’t have any available positions right now, but keep an eye out since we are constantly looking for more people to join our awesome team!',
  CAREERS_NO_POSITIONS_DESC_2: 'If you feel like we could use your talent today, contact us',
  MORE_INFORMATION_ABOUT_POSITION: 'More information about the position',
  SOUNDS_LIKE_YOU: 'Sounds like you?',
  YOUR_NAME: 'Your name',
  YOUR_EMAIL: 'Your e-mail',
  YOUR_LINKEDIN: 'Your LinkedIn URL',
  WHY_DO_YOU_WANT_TO_JOIN_US: 'Why do you want to join us?',
  APPLY_NOW: 'Apply now',
  LATEST_POSTS: 'Latest blog posts',
  ABOUT_US: 'About us',
  OUR_PARTNERS: 'Our partners',
  CONTACT_US: 'Contact us',
  ANY_QUESTIONS: 'Have any questions?',
  NICE: 'Nice!',
  YOU_ARE_ALL_SIGNED_UP: 'You are all signed up for a demo!',
  WE_WILL_CONTACT_YOU:
    'We will contact you as soon as possible to make the arrangements regarding date and time for the demo.',
  LOOKING_FORWARD_MEETING_YOU: 'Looking forward meeting you!',
  WE_GOT_YOUR_INFORMATION: 'We got your information!',
  WE_ARE_HAPPY_THAT_YOU_WROTE: 'We are happy that you wrote to us! We will contact you ASAP.',
  THIS_WEBSITE_USES_COOKIES: 'This website uses cookies',
  WE_USE_COOKIES:
    'We use cookies. You can opt out of these cookies at any time by changing the settings of the browser on the device you are using and deleting the cookies stored.',
  REJECT_COOKIES: 'Reject cookies',
  ALLOW_COOKIES: 'Allow cookies',
  I_WOULD_LIKE_TO_RECEIVE_NEWSLETTER: 'I would like to receive LaasOne newsletter',
  SO_FAR: 'So far',
  COST_REDUCTION: 'cost reduction',
  PRIVACY_POLICY: 'Privacy policy',
  COOKIE_POLICY_FILE_NAME: 'About cookies',
  COOKIE_NOTICE: 'Cookie notice',
  COOKIE_PRE_TEXT: 'Your consent applies to the following domains:',
  COOKIE_UPDATED: 'Cookie declaration last updated on: ',
  WHAT_ARE_COOKIES_HEADING: 'What is a Cookie?',
  WHAT_ARE_COOKIES_TEXT:
    'A cookie is a small data file stored on your computer, tablet or mobile phone. A cookie is not a program that may contain harmful programs or viruses.',
  COOKIES_ON_THIS_WEBSITE_HEADING: 'How / Why a website uses cookies',
  COOKIES_ON_THIS_WEBSITE_TEXT:
    'Cookies are necessary for the operation of the website. Cookies will help us get an overview of your visit on the website so that we can constantly optimize and customize the website according to your needs and interests. For example, cookies remember what you added to your shopping cart when you visited the page before you logically logged in and what languages and currencies you want to display on the website. We also use cookies to direct you to your ads on other websites. At a very general level, cookies are used as part of our services to show you the most important content as possible.',
  COOKIES_HOW_LONG_HEADING: 'How long cookies are kept?',
  COOKIES_HOW_LONG_TEXT:
    'The time when cookies are kept on your device may vary. Their scheduled expiration time is calculated from the last date when you visited the website. If the cookies expire, they are automatically deleted. ',
  COOKIES_DECLINE_HEADING: 'This way you can reject or delete your cookies',
  COOKIES_DECLINE_TEXT:
    'You can always disable cookies on your computer, tablet or phone by changing the browser settings. The location of these settings depends on the type of browser used. If you change settings, you need to know that you cannot use some features and services as they depend on the ability of the website to remember your choices.',
  COOKIES_DELETE_HEADING: 'Deleting cookies',
  COOKIES_DELETE_TEXT:
    'You can delete the cookies you have previously accepted. If you use a computer with the latest version of the browser, you can delete your cookies with the following keyboard shortcuts: Ctrl + Shift + Delete.',
  COOKIES_DELETE_TEXT2:
    'If the keyboard shortcuts do not work and / or you are using Apple PC, you will need to find out which browser you use and then click on the appropriate link:',
  COOKIES_DELETE_TEXT3:
    'Remember: if you use multiple different browsers, you must delete the cookies in all of them.',
  COOKIES_QUESTIONS_HEADING: 'You have any questions?',
  COOKIES_QUESTIONS_TEXT:
    'If you have any questions or comments about this information and / or personal data processing, please contact us.',
  NOT_FOUND_HEADING: 'Oh no!',
  NOT_FOUND_DESC: 'The page you are looking for does not exist.',
  NOT_FOUND_DESC_2: 'Error code: 404',
  GO_TO_HOMEPAGE: 'Go to homepage',
  FORM_SOMETHING_WENT_WRONG: 'Something went wrong! Please try again.',
  COUNTRY: 'Country',
  PHONE_NUMBER: 'Telephone number',
  ANNUAL_FREIGHT_SHIPMENTS: 'Annual Freight Shipments',
  EMAIL: 'E-mail',
  COMPANY_NAME: 'Company name',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  LOGIN: 'Login',

  // BLOG

  PUBLISHED: 'Published',
  AUTHOR: 'Author',
  TAGS: 'Tags',
  READ_MORE_ABOUT_LAASONE: 'Read more about LaasOne',
  LOAD_MORE_POSTS: 'Load more posts',
  GO_TO_LAASONE_HOMEPAGE: 'Go to LaasOne homepage',

  // CASE STUDIES

  READ_MORE_ABOUT: 'Read more about',

  CRYSTALSPACE_TEXT:
    'LaasOne helped us with the logistics of shipping high-tech cameras from Estonia to the US. They also arranged all the paperwork for the export, which is the most complicated part of space technology logistics.',
  CRYSTALSPACE_READ_MORE_TEXT: '',
  CRYSTALSPACE_AUTHOR: 'Jaan Viru, Founder and CTO of Crystalspace OÜ',

  RADIUS_TEXT:
    'As a CEO, central management in logistics platforms is invaluable to me. This allows me to get a quick overview and an analysis of the status and cost of our logistics operations. So we can focus on our business and the supporting tasks like logistics are managed by professionals at the LaasOne team.',
  RADIUS_READ_MORE_TEXT: '',
  RADIUS_AUTHOR: 'Ahti Talts, CEO of Radius Machining',

  POHJALA_TEXT:
    'We immediately liked the simplicity of the LaasOne logistics platform. It makes it very easy for all parties to track the location of the goods, arrival time etc. In addition to that, I very much appreciate their personalised customer service that larger companies often do not offer.',
  POHJALA_READ_MORE_TEXT: '',
  POHJALA_AUTHOR: 'Rait Kulli, Production manager of Põhjala Brewery',

  FINNROTOR_TEXT:
    'Finn-Rotor Estonia Oü ships goods all around the world. With LaasOne we can have all shipments in one simple system. This has freed time from our team and saved us money in transport costs. In case of special needs, LaasOne team finds us the best solution',
  FINNROTOR_READ_MORE_TEXT: '',
  FINNROTOR_AUTHOR: 'Matti Övermark, CEO',

  MERREM_TEXT:
    'Our company is constantly growing and logistics is becoming more important for us. LaasOne has made logistics easy to manage and cost-efficient. LaasOne has kept our costs down as we have grown annually almost 20%',
  MERREM_READ_MORE_TEXT: '',
  MERREM_AUTHOR: 'Alar Künnapuu, CEO',

  // TEAM DATA

  VILLE_PELTOLA_TITLE: 'CEO',
  JUSSI_PELTOLA_TITLE: 'Business development director',
  LAAS_KASK_TITLE: 'Head of operations',
  TALVAR_NURK_TITLE: 'Software development',
  MARGUS_MAJOROV_TITLE: 'Logistics specialist',
  LAURA_MIKK_TITLE: 'Logistics specialist',
  INDREK_RAHULA_TITLE: 'Logistics specialist',
  VAINO_MIIL_TITLE: 'Sales',

  // METADATA

  PAGE_TITLE: 'LaasOne – Your Digital Logistics Department',
  PAGE_DESCRIPTION:
    'LaasOne automates logistics management and pricing for small and mid-sized industrial enterprises. 50% more efficient, 10% lower transport costs, support by logistics professionals.',

  // ELEMENTS IDS

  ID_BOOK_A_DEMO_BTN: 'bookademo_en_button',
  ID_BOOK_A_DEMO_BTN_LABEL: 'bookademo_en_label',

  ID_LOGIN_BTN: 'login_en_button',
  ID_LOGIN_BTN_LABEL: 'login_en_label',
  ORDER_FORM: {
    TITLE: 'Instant quotation & order for companies',
    FROM: 'From',
    TO: 'To',
    FORM_TO_PLACEHOLDER: 'Country, city and zip',
    SUBMIT: 'Search prices',
    CARGO: 'Cargo',
    WEIGHT: 'Weight',
    WEIGHT_PLACEHOLDER: 'KG',
    AMOUNT: 'Amount',
    PACKAGE: 'Package',
    PALLET: 'Pallet',
    MEASURES: 'Measures (cm)',
    LENGTH_PLACEHOLDER: 'Length',
    WIDTH_PLACEHOLDER: 'Width',
    HEIGHT_PLACEHOLDER: 'Height',
    NO_PRICES_FOUND:
      'We could not generate instant prices for requested route. \n Please send price request and we will replay within 24 hours!',
    SEND_QUOTE: 'CLICK TO MAKE PRICE REQUEST',
    ORDER: 'CLICK TO ORDER',
    PRICE: 'PRICE',
    EXCL_VAT: 'excl. vat',
    SEND_QUOTATION_TITLE: 'Send a price quotation',
    CANCEL_QUOTATION: 'Cancel',
    SEND_QUOTATION: 'SEND',
    SEND_QUOTATION_INFORMATION:
      'Leave us your contacts and we will find a proper solution and a price and get back to you.',
    CONTACT_COMPANY: 'Company name',
    CONTACT_NAME: 'Contact',
    CONTACT_EMAIL: 'Email',
    CONTACT_PHONE: 'Phone',
    YOUR_CONTACT: 'Your contact',
    CLOSE: 'Close',
    QUOTATION_SENT: 'Quotation email sent successfully',
  },
  SELECT_COUNTRY: 'Select country',
  TRANSPORT: {
    TITLE: 'Fill in your customer client code',
    CUSTOMER_NOT_FOUND: 'Company not found',
    REG_CODE: 'Reg. code',
    ADDRESSES: 'Address',
    BTN_ORDER: 'ORDER TRANSPORT',
  },
  ORDER: {
    TITLE: 'Order transport from us',
    ITEMS: 'Item to your shipment',
    ADDRESSES: 'Add route addresses',
    DATES: 'Add dates for pickup and delivery',
    PRICE: "Your shipment's price",
    SUBMIT: 'Order',
    YOUR_INFORMATION: 'Fill in your company information',
    ADDRESS: {
      COMPANY_INFO: 'Address and contact',
      COMPANY_REG: 'Reg nr.',
    },
    SEND_MODAL_TITLE: 'Transport order',
    SEND_MODAL_TEXT: 'Please be sure that you inserted correct data!',
    ORDER_SENT: 'Your order was saved successfully',
    CLOSE: 'Continue',
    CANCEL: 'Cancel',
    ADD_REFERENCE: 'Add reference number',
    REFERENCE: 'Reference number',
    ADDRESS_TOOLTIP:
      'Please insert correct e-mail addresses to receive notifications about the order',
  },
  PRICE_REQUEST: {
    DAYS_DELIVERY: 'days delivery',
    SAME_DAY_DELIVERY: 'Same day delivery',
    ON_TIME_DELIVERY: 'On time delivery',
    THIS_OPTION_IS_CHOSEN: 'This option is chosen',
  },
  SHIPMENT: {
    I_NEED_PICKUP_WITH_A_TAIL_LIFT_TRUCK: 'I need pickup with a tail lift truck',
    PICK_ONE_PRICE_ERROR: 'Please pick one price for the approval',
    SEND: 'Send',
    SEND_MESSAGE: 'Send message',
    HAS_FILES: 'Shipment has files',
    COMMENTS: 'Messages',
    HAS_COMMENT: 'Has messages',
    NEW_COMMENT: '1 new message',
    NEW_COMMENTS: '{0} new messages',
    NO_NEW_COMMENTS: 'No new messages',
    ADD_A_COMMENT: 'Add a message',
    CLEAR_THE_FIELD: 'Clear the field',
    SHIPMENT_TYPE: 'Send/Receive items',
    TYPE: {
      SENDER: 'I am sending items',
      RECEIVER: 'I am receiving items',
    },
    YOU_HAVE_UNSAVED_CHANGES: 'You have unsaved changes.',
    DISCARD_SHIPMENT: 'Discard shipment',
    TITLE_ADD_NEW_SHIPMENT_FROM_TITLE: 'Add a new shipment from template',
    BTN_ADD_SHIPMENT: 'Add shipment',
    FROM_TEMPLATE_DESCRIPTION:
      'To create and manage templates, open “Templates” under user menu in the header.',
    BASED_ON_SHIPMENT: 'Based on the shipment {0}. ',
    SHIPMENT_CREATED: 'Shipment created {0}',
    TRANSPORT_WITH_TAIL_LIFT_TRUCK: 'Transport with tail lift truck',
    I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK_TOOLTIP:
      'Truck with a mechanical device permanently installed on the rear. It is designed to load goods from ground level or a loading dock to the level of the vehicle bed, or vice versa.',
    SAVE_SHIPMENT_AS_A_TEMPLATE: 'Save shipment {0} as a template',
    THIS_SHIPMENT_IS_NOT_SAVED_AS_TEMPLATE: 'This shipment is not saved as template',
    THIS_SHIPMENT_IS_SAVED_AS_TEMPLATE: 'This shipment is based on template',
    REMOVE_TEMPLATE_CONFIRM_TEXT: 'Are you sure you want to remove "{0}" from templates?',
    SAVE_THIS_SHIPMENT_AS_TEMPLATE_BUTTON: 'Save this shipment as a template',
    CANCEL_CONFIRMATION_DIALOG_TEXT:
      'Are you sure you want to cancel? The changes to shipment draft will not be saved.',
    REMOVE_TEMPLATE_BUTTON: 'Remove template',
    REMOVE_FROM_TEMPLATES: 'Manage templates',
    NAME_YOUR_TEMPLATE: 'Name your template',
    MANAGE_TEMPLATES: 'Manage templates',
    SAVE_TEMPLATE: 'Save template',
    TEMPLATE_NAME: 'Template name',
    NOTIFICATIONS_FOR_THIS_SHIPMENT: 'Notifications for this shipment',
    SEND_SHIPMENT: 'Send shipment',
    SAVE_AS_DRAFT: 'Save as draft',
    SAVE_AS_DRAFT_SUCCESS: 'Shipment has been saved as draft',
    DELETE_DRAFT: 'Delete draft',
    DELETE_DRAFT_CONFIRMATION: 'Are you sure you want to delete shipment draft number {0}?',
    DELETE_DRAFT_SUCCESS: 'Shipment draft successfully deleted',
    SHIPMENTS: 'Shipments',
    SHIPMENT: 'Shipment',
    SHIPMENT_NR: 'Shipment nr',
    REF_NR: 'Ref nr',
    REF_MISSING: 'Ref missing',
    CARRIER_MISSING: 'Carrier missing',
    PICKUP_MISSING: 'Pickup address missing',
    DELIVERY_MISSING: 'Delivery address missing',
    DATE_MISSING: 'Date missing',
    ADD_REFERENCE_NUMBER: 'Add reference number',
    YOUR_SHIPMENTS_PRICE: "Your shipment's price",
    YOUR_SHIPMENTS_PRICE_DESC:
      'Prices shown are indicative and can change if actual items do not match the inserted values',
    NO_PRICES: 'To see prices for your shipment, fill in information about items and addresses.',
    NO_AUTOMATIC_PRICES:
      "Unfortunately we don't support automatic prices for your shipment. Operator will take a look after you send it and let you know what we can offer.",
    AUTOMATIC_PRICES_IN_PROGRESS:
      'We are working hard to find the best prices for the items and routes you inserted!',
    REFERENCE_NUMBER: 'Reference number',
    REFERENCE_NUMBER_TOOLTIP:
      'Number specific to the goods or the shipment in the customer systems.',
    NOTIFY_ME_ABOUT_THIS_SHIPMENT: 'Notify me about this shipment',
    SAVE_THIS_SHIPMENT_AS_TEMPLATE: 'Save this shipment as template',
    SAVE_THIS_SHIPMENT_AS_TEMPLATE_TOOLTIP:
      'Possibility of saving the shipment as a template to use the same data in the future while making a new shipment.',
    EARLIEST_PICKUP_DATE: 'Earliest pickup date',
    LATEST_PICKUP_DATE: 'Latest pickup date',
    EARLIEST_DELIVERY_DATE: 'Earliest delivery date',
    LATEST_DELIVERY_DATE: 'Latest delivery date',
    FIXED_PICKUP_DATE: 'Fixed pickup date',
    FIXED_DELIVERY_DATE: 'Fixed delivery date',
    EARLIEST_PICKUP_TIME: 'Earliest pickup time',
    LATEST_PICKUP_TIME: 'Latest pickup time',
    EARLIEST_DELIVERY_TIME: 'Earliest delivery time',
    LATEST_DELIVERY_TIME: 'Latest delivery time',
    FIXED_PICKUP_TIME: 'Fixed pickup time',
    FIXED_DELIVERY_TIME: 'Fixed delivery time',
    PLEASE_CHECK_YOUR_SHIPMENT_INFORMATION_BEFORE_CONFIRMING:
      'Please check your shipment information before confirming',
    SIDEBAR: {
      ACTIONS: 'Copy shipment',
    },
    DATES: {
      ADD_PICKUP_DELIVERY_DATES: 'Add dates for pickup and delivery',
      PICKUP_DATES: 'Pickup dates',
      DELIVERY_DATES: 'Delivery dates',
      PICK_PICKUP_DATES: 'Add earliest and latest pickup dates',
      PICK_DELIVERY_DATES: 'Add earliest and latest delivery dates',
      PICK_PICKUP_FIXED_DATE: 'Add fixed pickup date',
      PICK_DELIVERY_FIXED_DATE: 'Add fixed delivery date',
    },
    NAME_THIS_SHIPMENT_TEMPLATE: 'Name this shipment template',
    ADDRESSES: {
      CLIENT_CODE: 'Client code',
      ADD_ADDRESS: 'Add address',
      UPDATE_ADDRESS: 'Update address',
      ADD_ROUTE_ADDRESSES: 'Add route addresses',
      REQUIRED_FIELDS_ARE_MARKED_WITH: 'Required fields are marked with',
      WHERE_TO_PICK_UP_FROM: 'Where to pick up from?',
      WHERE_TO_DELIVER: 'Where to deliver?',
      RECENTLY_USED_ADDRESSES: 'Recently used addresses',
      CHOOSE_FROM_ADDRESS_BOOK: 'Choose from address book',
      ADD_MANUALLY: 'Add manually',
      CLEAR_ALL_FIELDS: 'Clear all fields',
      SAVE_THIS_ADDRESS: 'Save this address:',
      NAME_THIS_ADDRESS: 'Name this address',
      SENDER_AND_DELIVERY_ADDRESSES: 'Sender and delivery addresses',
      TYPE_HERE_THE_ADDRESS_AND_SELECT_IT_FROM_GOOGLE_RESULTS_OR_FROM_ADDRESS_BOOK:
        'Type here the address and select it from Google results or from address book',
      COUNTRY: 'Country',
      CITY: 'City',
      POSTCODE: 'Postcode',
      COMPANY_NAME: 'Company name',
      CONTACT_PERSON: 'Contact person',
      CONTACT_NUMBER: 'Contact number',
      EMAIL: 'E-mail',
      REFERENCE: 'Reference',
      LOADING_REFERENCE: 'Loading reference',
      LOADING_REFERENCE_TOOLTIP: 'Number specific to the pickup loading place/building etc.',
      UNLOADING_REFERENCE: 'Unloading reference',
      UNLOADING_REFERENCE_TOOLTIP: 'Number specific to the delivery loading place/building etc.',
      ADD_AN_ALTERNATIVE_ADDRESS: 'Add an alternative address',
      ADDRESS_DETAILS: 'Address details',
      ADDRESS: 'Address',
      ADD_MORE_INFORMATION: 'Add more information',
      CLOSE_MORE_INFORMATION: 'Close more information',
      SENDER: {
        INFORMATION: 'Sender information',
        ADDRESS: 'Sender address',
        CONTACT_PERSON: 'Sender contact person',
        ADDRESS_DETAILS: 'Sender address details',
        ADDRESS_DETAILS_TOOLTIP:
          'Details related to the address to indicate a specific place for the pickup of goods (side of the building, door, terminal etc)',
        ALTERNATIVE_ADDRESS: 'Alternative sender address',
        ALTERNATIVE_CONTACT_PERSON: 'Alternative sender contact person',
        ALTERNATIVE_ADDRESS_DETAILS: 'Alternative sender address details',
        ADD_AN_ALTERNATIVE_ADDRESS: 'Add an alternative sender address',
      },
      COMPANY: {
        ADDRESS: 'Company address',
      },
      RECEIVER: {
        INFORMATION: 'Receiver information',
        ADDRESS: 'Receiver address',
        CONTACT_PERSON: 'Receiver contact person',
        ADDRESS_DETAILS: 'Receiver address details',
        ADDRESS_DETAILS_TOOLTIP:
          'Details related to the address to indicate a specific place for the delivery of goods (side of the building, door, terminal etc)',
        ALTERNATIVE_ADDRESS: 'Alternative receiver address',
        ALTERNATIVE_CONTACT_PERSON: 'Alternative receiver contact person',
        ALTERNATIVE_ADDRESS_DETAILS: 'Alternative receiver address details',
        ADD_AN_ALTERNATIVE_ADDRESS: 'Add an alternative receiver address',
      },
    },
    ADD_ITEMS_TO_YOUR_SHIPMENT: 'Add items to your shipment',
    CLICK_ON_THE_ITEM_YOU_WANT_TO_ADD: 'Click on the item you want to add',
    ITEMS_ADDED: 'Items added',
    CLICK_ONE_OR_MORE_ITEMS_ABOVE_TO_START_ADDING_THEM_TO_YOUR_SHIPMENT:
      'Click one or more items above to start adding them to your shipment',
    I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK: 'I need delivery with a tail lift truck',
    ADD_A_COMMENT_REGARDING_THE_ITEMS_ADDED: 'Add a comment regarding the items added',
    DOCUMENTS: {
      DELETE_TOOLTIP: 'This document cannot be deleted because it was created by the operator',
    },
    CMR_NOT_AVAILABLE: 'This document cannot yet be downloaded because the carrier is not selected',
    POD_NOT_AVAILABLE: 'This document cannot yet be downloaded because POD files are missing',
    ITEM: {
      TOTAL: 'Total',
      SELECT_TYPE: 'Select type',
      DETAILS: {
        PC: 'pc',
        TYPE: 'Type',
        LENGTH: 'Length',
        LENGTH_TOOLTIP: 'The length is fixed for selected item type',
        WIDTH: 'Width',
        WIDTH_TOOLTIP: 'The width is fixed for selected item type',
        HEIGHT: 'Height',
        VOLUME: 'Volume',
        WEIGHT_PC: 'Weight/pc',
        TOTAL_WEIGHT: 'Total weight',
        LDM: 'LDM',
        LDM_TOOLTIP:
          'Loading metres (running metres of trailer length in full width and height). This is automatically calculated based on length, width and quantity, but you can write a different value if needed.',
        REFERENCE_NUMBER: 'Reference number',
        PRODUCT_DESCRIPTION: 'Product description',
        UN_NO: 'UN no',
        UN_NO_TOOLTIP: 'Number, that identifies dangerous goods, hazardous substances and articles',
        UN: 'UN',
        UN_CODE: 'UN code',
        PG: 'PG',
        PG_TOOLTIP:
          'Dangerous goods are assigned into 3 packing groups in accordance with the degree of danger they present',
        CLASS: 'Class',
        CLASS_TOOLTIP: 'Classification numbers for the dangerous goods',
        DANGEROUS_GOODS: 'Dangerous goods',
        COLD_FROZEN: 'Cold/frozen',
        STACKABLE: 'Stackable',
        DELICATE: 'Delicate',
        MIN_TEMP: 'Min temp',
        MIN_TEMP_TOOLTIP: 'Minimum temperature of the goods that they require for transport',
        MAX_TEMP: 'Max temp',
        MAX_TEMP_TOOLTIP: 'Maximum temperature of the goods that they require for transport',
        PROPER_SHIPPING_NAME: 'Proper shipping name',
        PROPER_SHIPPING_NAME_TOOLTIP:
          'Name to describe the hazard properties and the composition of dangerous goods',
        PROPER_SHIPPING_NAME_PLACEHOLDER: 'Black powder, compressed or Gunpowder',
        PACKAGE_INFO:
          'Mark the measurement of the longest side on the field "Length" when the package is stackable.',
      },
      TYPE: {
        PALLET: 'Pallet',
        PACKAGE: 'Package',
        TRUCK: 'Truck',
        OTHER: 'LDM',
        CONTAINER: 'Container',
      },
      SUBTYPE: {
        EUR_PALLET: 'EUR pallet',
        FIN_PALLET: 'FIN pallet',
        PALLET: 'Pallet',
        PACKAGE: 'Package',
        BOX: 'Box',
        FULL_TRUCK_LOAD: 'Full truck load',
        LONG_PALLET: 'Long pallet',
        HALF_PALLET: 'Half pallet',
        LDM: 'LDM',
        CONTAINER_20_DC: '20’DC',
        CONTAINER_40_DC: '40’DC',
        CONTAINER_40_HC: '40’HC',
        CONTAINER_OTHER: 'Other',
      },
      CLASS: {
        EXPLOSIVES: 'Explosives',
        GASES: 'Gases',
        FLAMMABLE_LIQUIDS: 'Flammable Liquids',
        FLAMMABLE_SOLIDS: 'Flammable Solids',
        OXIDIZERS: 'Oxidizers',
        TOXIC_SUBSTANCES: 'Toxic Substances',
        RADIOACTIVE: 'Radioactive',
        CORROSIVES: 'Corrosives',
        MISCELLANOUS: 'Miscellanous',
      },
      PG: {
        PG1: 'Packing Group I: high danger',
        PG2: 'Packing Group II: medium danger',
        PG3: 'Packing Group III: low danger',
      },
    },
    DELETE_DOCUMENT: 'Delete document',
    DELETE_CONFIRM_TEXT: 'Are you sure you want to delete document "{0}"?',
    ADD_DOCUMENT_MODAL_TITLE: 'Add new document',
    ADD_DOCUMENT_BUTTON: 'Add document',
    CUSTOMER_CONFIRMED: 'Customer confirmed: {0}',
    PICKUP_ON: 'Pickup on: {0}',
    PICKUP_FROM: 'Pickup from: {0}',
    PICKUP_BETWEEN: 'Pickup between: {0} and {1}',
    DELIVERY_ON: 'Delivery on: {0}',
    DELIVERY_FROM: 'Delivery on: {0}',
    DELIVERY_BETWEEN: 'Delivery between: {0} and {1}',
    IMPORT_MODAL_TITLE: 'Import shipments from Excel file',
    IMPORT_MODAL_TITLE_CORRECT_ROWS: '{0} shipments successfully imported',
    IMPORT_MODAL_TITLE_INCORRECT_ROWS: 'No shipments were imported from Excel file',
    IMPORT_MODAL_TITLE_SMALL: 'Only one Excel file can be imported at once.',
    BTN_IMPORT: 'Import shipments',
    BTN_IMPORT_FINISH: 'Ok, understood.',
    BTN_IMPORT_CORRECT_ROWS: 'Ok, understood. Will upload a new file',
    BTN_IMPORT_DOWNLOAD_ERROR_XLSX: 'Download file with error rows (.xlsx)',
    ERROR_MALFORMED_ROWS_TEXT:
      'Some rows shown below have validation errors and therefore they cannot be imported',
    ERROR_DUPLICATED_ROWS_TEXT:
      'Some shipments shown below are already saved and therefore they cannot be imported',
    EXCEL_ROW_NUMBER: 'Excel row number',
    EXCEL_ROW_MISSING_WRONG_INFO: 'Missing or wrong information',
    EXCEL_ROW_EXISTS: 'Shipment that exists with the same information',
    EXCEL_IMPORT_ERRORS: {
      SENDER_REQUIRED: 'Sender is missing',
      SENDER_ADDRESS_REQUIRED: 'Sender address is missing',
      SENDER_CITY_REQUIRED: 'Sender city is missing',
      SENDER_POST_CODE_REQUIRED: 'Sender postcode is missing',
      SENDER_COUNTRY_REQUIRED: 'Sender country is missing',
      SENDER_CONTACT_PERSON_REQUIRED: 'Sender contact person name is missing',
      SENDER_CONTACT_PHONE_REQUIRED: 'Sender contact person phone is missing',
      RECEIVER_REQUIRED: 'Receiver is missing',
      RECEIVER_ADDRESS_REQUIRED: 'Receiver address is missing',
      RECEIVER_CITY_REQUIRED: 'Receiver city is missing',
      RECEIVER_POST_CODE_REQUIRED: 'Receiver postcode is missing',
      RECEIVER_COUNTRY_REQUIRED: 'Receiver country is missing',
      RECEIVER_CONTACT_PERSON_REQUIRED: 'Receiver contact person name is missing',
      RECEIVER_CONTACT_PHONE_REQUIRED: 'Receiver contact person phone is missing',
      PICKUP_DATE_ERROR: 'Pickup date is missing',
      DELIVERY_DATE_ERROR: 'Delivery date is missing',
      DELIVERY_DATE_IS_EARLIER_THAN_PICKUP_ERROR: 'Tarne kuupäev on varasem kui korje kuupäev',
      QUANTITY_ERROR: 'Quantity is missing',
      PACKAGE_ERROR: 'Package type is missing',
      WEIGHT_ERROR: 'Weight is missing',
      WIDTH_ERROR: 'Width is missing',
      LENGTH_ERROR: 'Length is missing',
      HEIGHT_ERROR: 'Height is missing',
      PICKUP_DATE_IN_PAST: 'Pickup date is in the past',
      LDM_ERROR: 'LDM is missing',
    },
    BTN_DOWNLOAD_XLSX_TEMPLATE: 'Download template (.xlsx)',
  },
  VALIDATION: {
    POSTCODE_IS_REQUIRED: 'Postcode is missing',
  },
  ADD_DOCUMENTS_TITLE: 'Add documents',
  DROPZONE: {
    DRAG_AND_DROP: 'Drag and drop a file here or',
    CHOOSE_FROM_COMPUTER: 'choose from your computer',
    SUPPORTED_FILES: 'Supported file types: PDF, PNG, JPG, JPEG',
    SUPPORTED_FILES_XLS: 'Supported file types: XLSX',
    ERROR: 'File “{0}” has a file type that is not permitted',
  },
  SALES_EST: 'Sales in Baltics',
  SALES_FI: 'Sales in Scandinavia',
  TITLE_ADMINISTRATION: 'Finance',
  TITLE_GENERAL: 'General',
  TORMMETALL_TEXT:
    'Olen äärmiselt rahul LaasOne’i professionaalse suhtumise ja teenuse kvaliteediga. Nende meeskond on sõbralik, abivalmis ning ehk mis kõige tähtsam – kiire reageerimisvõimega, mis teeb koostöö nendega sujuvaks ja usaldusväärseks. LaasOne on suurepärane näide ettevõttest, mis on digitaliseerumisega täiesti sammu pidanud. Soovitan LaasOne’i kindlasti kõigile, kes otsivad tõhusat ja usaldusväärset logistikateenust.',
  TORMMETALL_READ_MORE_TEXT: '',
  TORMMETALL_AUTHOR: 'Sander Poljakov, Logistik',
  KONETALO_READ_MORE_TEXT: '',
  KONETALO_TEXT:
    'With LaasOne, we got logistics under better control and costs under control. A service that we really dare to recommend!',
  KONETALO_AUTHOR: 'Timo Peltola, CEO',
  HAPPY_BIRTHDAY: 'LaasOne is now Laas Fifty.',
  HAPPY_BIRTHDAY2: 'This just to celebrate our colleague Laas Kask´s 50 years!',
  BTN_CLOSE: 'Close',
  COMPANY: {
    OFFICES: 'Office and warehouse addresses',
  },
  PRIMARY_CONTACT: 'Your dedicated contact',
};
